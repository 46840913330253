<template>
  <v-container fluid class="pt-0">
    <!-- MENÜSOR START -->
    <v-row class="pa-3 primary">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon color="white" class="mr-3" v-on="on" @click="$router.go(-1)">
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>
              {{ $t('button.backToList') }}
            </span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon color="white" class="mr-3" v-on="on" @click="sendServiceRequestForm" :disabled="!requestForm">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('button.save') }}
        </span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!--v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon color="white" class="mr-3" v-on="on">
            <v-icon>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('button.export') }}
        </span>
      </v-tooltip-->
    </v-row>
    <!-- MENÜSOR END -->
    <!-- ŰRLAP START -->
    <v-form v-model="requestForm">
      <v-row class="mt-3">
        <!-- ESZKÖZ ADATOK START -->
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-devices</v-icon>
              <span class="subtitle-1">{{ $t('stepper.deviceDetails') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete v-model="serviceRequestForm.serviceRequest.brand"
                                  :items="selectFieldOptions.brands"
                                  :label="$t('inputLabels.manufacturer')"
                                  item-value="id"
                                  item-text="name"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled
                                  return-object
                                  required
                                  disabled>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select v-model="serviceRequestForm.serviceRequest.productGroup"
                            :items="selectFieldOptions.productGroups"
                            item-text="name"
                            item-value="id"
                            :no-data-text="$t('noDataText.productGroups')"
                            :label="$t('inputLabels.productGroup')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            persistent-hint
                            filled
                            return-object
                            required>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete v-model="serviceRequestForm.serviceRequest.productTypeFull"
                                  :items="selectFieldOptions.productTypes"
                                  item-text="displayName"
                                  item-value="productTypeId"
                                  :search-input.sync="typeSearch"
                                  :label="$t('inputLabels.productType')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  :no-data-text="$t('noDataText.general')"
                                  persistent-hint
                                  filled
                                  return-object
                                  required>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn1"
                                :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier1  || $t('inputLabels.imei1')"
                                :rules="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1 ? inputRules.imeiRule : []"
                                :messages="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1 ? $t('imeiHint') : $t('imeiHintOptional')"
                                :hint="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1 ? $t('imeiHint') : $t('imeiHintOptional') "
                                v-mask="imeiMask"
                                counter="15"
                                maxlength="15"
                                persistent-hint
                                filled
                                :disabled="!serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier1">
                  </v-text-field>
                  <v-slide-y-transition>
                    <v-row class="pa-0 ma-0" v-show="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier1">
                      <v-col class="px-3 py-0 caption">
                        {{ $t('generateRandomImei') }}
                      </v-col>
                      <v-col cols="auto" class="pa-0">
                        <v-btn small
                               color="primary"
                               @click="randomImei(1)">
                          {{ $t('button.generate') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-slide-y-transition>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn2"
                                :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier2 || $t('inputLabels.imei2')"
                                :rules="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2 ? inputRules.imeiRule : []"
                                :hint="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2 ? $t('imeiHint') : $t('inputMessages.optionalField')"
                                :messages="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2 ? $t(`${imeiHint}`) : $t('imeiHintOptional')"
                                v-mask="imeiMask"
                                counter="15"
                                maxlength="15"
                                persistent-hint
                                filled
                                :disabled="!serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier2">
                  </v-text-field>
                  <v-slide-y-transition>
                    <v-row class="pa-0 ma-0" v-show="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier2">
                      <v-col class="px-3 py-0 caption">
                        {{ $t('generateRandomImei') }}
                      </v-col>
                      <v-col cols="auto" class="pa-0">
                        <v-btn small
                               color="primary"
                               @click="randomImei(2)">
                          {{ $t('button.generate') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-slide-y-transition>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn3"
                                :label="serviceRequestForm.serviceRequest.productGroup.nameUniqueIdentifier3  || $t('inputLabels.serialNr')"
                                :rules="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier3 ? inputRules.snRule : []"
                                :hint="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier3 ? $t('inputMessages.requiredField') : $t('inputMessages.optionalField')"
                                counter="22"
                                maxlength="22"
                                persistent-hint
                                filled
                                :disabled="!serviceRequestForm.serviceRequest.productGroup.isUniqueIdentifier3">
                  </v-text-field>
                  <v-slide-y-transition>
                    <v-row class="pa-0 ma-0" v-show="serviceRequestForm.serviceRequest.productGroup.reqUniqueIdentifier3">
                      <v-col class="px-3 py-0 caption">
                        {{ $t('generateRandomImei') }}
                      </v-col>
                      <v-col cols="auto" class="pa-0">
                        <v-btn small
                               color="primary"
                               @click="randomImei(3)">
                          {{ $t('button.generate') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-slide-y-transition>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select v-model="serviceRequestForm.serviceRequest.warrantyType"
                            :items="selectFieldOptions.warrantyType"
                            item-text="name"
                            item-value="id"
                            :label="$t('inputLabels.warrantyType2')"
                            :rules="inputRules.selectRules"
                            :hint="$t('inputMessages.requiredField')"
                            persistent-hint
                            filled
                            return-object
                            required>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                    :label="$t('inputLabels.dateOfPurchase')"
                    :rules="inputRules.purchaseDateRule"
                    :hint="$t('inputMessages.purchaseDateReq')"
                    persistent-hint
                    v-mask="dateTextMask"
                    filled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="serviceRequestForm.calculatedRepairPrice"
                                :label="$t('inputLabels.repairCostLimit')"
                                :hint="$t('inputMessages.optionalField')"
                                suffix="Ft"
                                v-mask="priceMask"
                                persistent-hint
                                filled/>
                </v-col>
                <!--v-col cols="12" sm="6" md="3">
                  <v-text-field v-model="serviceRequestForm.serviceRequest.purchasePrice"
                                :label="$t('inputLabels.purchasePrice')"
                                :hint="$t('inputMessages.optionalField')"
                                suffix="Ft"
                                persistent-hint
                                filled
                                v-mask="priceMask"
                                required>
                  </v-text-field>
                </v-col-->

                <!--v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="serviceRequestForm.serviceRequest.dateOfDelivery"
                    :label="$t('inputLabels.dateOfDelivery')"
                    :rules="inputRules.purchaseDateRule"
                    :hint="$t('inputMessages.purchaseDateReq')"
                    persistent-hint
                    v-mask="dateTextMask"
                    filled>
                  </v-text-field>
                </v-col-->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- ESZKÖZ ADATOK END -->
        <!-- TOVÁBBI START -->
        <v-col cols="12" sm="6">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-cellphone-information</v-icon>
              <span class="subtitle-1">{{ $t('additionalDatas') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="serviceRequestForm.serviceRequest.partnerWorksheetNumber"
                                :label="$t('inputLabels.mieleWorksheetNumber')"
                                :hint="$t('inputMessages.requiredField')"
                                :rules="inputRules.generalRules"
                                persistent-hint
                                filled/>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="serviceRequestForm.serviceRequest.errorDescription"
                              :label="$t('inputLabels.troubleDescription')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              rows="1"
                              persistent-hint
                              filled/>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="serviceRequestForm.serviceRequest.accessories"
                              :label="$t('inputLabels.accessories')"
                              :hint="$t('inputMessages.optionalField')"
                              rows="1"
                              persistent-hint
                              filled/>
                </v-col>
                <!--v-col cols="12">
                  <v-file-input v-model="files"
                                :label="$t('inputLabels.attachedDocs')"
                                :rules="inputRules.maxFileSizeOptional"
                                :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                persistent-hint
                                multiple
                                accept="image/*, .pdf, .doc, .docx"
                                counter
                                show-size
                                :disabled="inputsDisabled"
                                filled
                                ref="fileInput">
                    <template v-slot:selection="{text, file}">
                      <v-chip small
                              label
                              close
                              @click:close="removeFileFromInput(file)"
                              text-color="white"
                              :color="file.size > 5000000 ? 'red' : 'primary'">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col-->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- TOVÁBBI END -->
        <!-- KÉPEK START -->
        <v-col cols="12" sm="6">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-file-document</v-icon>
              <span class="subtitle-1">{{ $t('documentUpload') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <v-col cols="12">
                  <v-file-input v-model="attachment.invoice"
                                :label="$t('inputLabels.invoice')"
                                :rules="inputRules.maxFileSizeOptional"
                                :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                persistent-hint
                                accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                counter
                                show-size
                                filled
                                ref="valami">
                    <template v-slot:selection="{text}">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-file-input v-model="attachment.warrantyCard"
                                :label="$t('inputLabels.warrantyTicket')"
                                :rules="inputRules.maxFileSizeOptional"
                                :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                persistent-hint
                                accept=".pdf,.PDF,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                counter
                                show-size
                                filled>
                    <template v-slot:selection="{text}">
                      <v-chip small label color="primary">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <!--v-col cols="12">
                  <v-file-input v-model="files"
                                :label="$t('inputLabels.otherDocsPics')"
                                :rules="inputRules.maxFileSizeOptional"
                                :hint="$t('inputMessages.maxFileSizeRuleOptional')"
                                persistent-hint
                                multiple
                                accept="image/*, .pdf, .doc, .docx"
                                counter
                                show-size
                                filled
                                ref="fileInput">
                    <template v-slot:selection="{text, file}">
                      <v-chip small
                              label
                              close
                              @click:close="removeFileFromInput(file)"
                              text-color="white"
                              :color="file.size > 5000000 ? 'red' : 'primary'">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col-->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- KÉPEK END -->
        <!-- ÜGYFÉL ADATOK START -->
        <v-col cols="12" lg="6">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
              <span class="subtitle-1">{{ $t('stepper.customerDetails') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequestForm.customer.lastName"
                                :label="$t('inputLabels.lastName')"
                                :rules="inputRules.noSpecialCharAndReq"
                                :hint="$t('inputMessages.requiredField')"
                                persistent-hint
                                counter="30"
                                filled
                                required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequestForm.customer.firstName"
                                :label="$t('inputLabels.firstName')"
                                :rules="inputRules.noSpecialCharAndReq"
                                :hint="$t('inputMessages.requiredField')"
                                persistent-hint
                                counter="30"
                                filled
                                required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequestForm.customer.email"
                                :label="$t('inputLabels.email')"
                                :rules="inputRules.emailRules"
                                :hint="$t('inputMessages.requiredField')"
                                persistent-hint
                                counter="30"
                                filled
                                required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequestForm.customer.phoneNumber"
                                :label="$t('inputLabels.phone')"
                                :rules="inputRules.phoneRules"
                                :hint="$t('inputMessages.requiredField')"
                                persistent-hint
                                prefix="+36-"
                                v-mask="mask"
                                filled
                                required>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- ÜGYFÉL ADATOK END -->
        <!-- CÍM ADATOK START -->
        <v-col cols="12" lg="6">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-truck-fast-outline</v-icon>
              <span class="subtitle-1">{{ $t('innerSideNav.addressInformation') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="deliveryInAddressString"
                                :label="$t('deliveryAddress')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                persistent-hint
                                filled
                                append-icon="mdi-pencil"
                                readonly
                                @click="deliveryInAddressModal = true"
                                @click:append="deliveryInAddressModal = true">
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0" v-if="serviceRequestForm.isStoreDeliveryOut === 0">
                  <v-checkbox v-model="billingAddressIsEqualdeliveryInAddress"
                              class="mt-0"
                              :disabled="!(deliveryInAddressForm || deliveryInAddressString.length > 2)">
                    <template slot="label">
                      <span class="body-2">
                        {{ $t('invoicingAddressIsEqualShippingAddress') }}
                      </span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-text-field v-model="billingAddressString"
                                :label="$t('invoicingAddress')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                persistent-hint
                                filled
                                append-icon="mdi-pencil"
                                readonly
                                :disabled="billingAddressIsEqualdeliveryInAddress"
                                @click="billingAddressModal = true"
                                @click:append="billingAddressModal = true">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- CÍM ADATOK END -->
      </v-row>
    </v-form>
    <!-- ŰRLAP END -->
    <!-- MENÜSOR START -->
    <v-row class="pa-3" justify="end">
      <!--v-btn color="primary" class="mr-3" v-if="$route.params.ws !== 'uj-megrendeles'">
        {{ $t('button.edit') }}
      </v-btn-->
      <v-btn color="primary" class="mr-3" @click="sendServiceRequestForm" :disabled="!requestForm">
        {{ $t('button.save') }}
      </v-btn>
    </v-row>
    <!-- MENÜSOR END -->
    <!-- SZÁLLÍTÁSI CÍM MODAL START -->
    <v-dialog v-model="deliveryInAddressModal" max-width="800" persistent>
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
          <span class="white--text">{{ $t('deliveryAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="deliveryInAddressModal = false" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-form v-model="deliveryInAddressForm">
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.postalCode"
                                :items="selectFieldOptions.postalCodes"
                                item-text="address.postalCode"
                                item-value="address.postalCode"
                                :search-input.sync="postalCodeSearch"
                                :label="$t('inputLabels.postalCode')"
                                :rules="inputRules.phoneRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                maxlength="4"
                                required
                                @change="onPostalCodeSelect"
                                @click:clear="clearAllAddressInputs"
                                clearable>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.city"
                                :items="selectFieldOptions.cities"
                                item-value="address.city"
                                item-text="address.city"
                                :search-input.sync="citySearch"
                                :label="$t('inputLabels.city')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                required
                                clearable
                                @click:clear="clearAllAddressInputs">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8">
                <v-combobox v-model="streetIdeiglenes"
                            :items="selectFieldOptions.streets"
                            item-value="address.street"
                            item-text="address.street"
                            :search-input.sync="streetSearch"
                            :label="$t('inputLabels.publicPlaceName')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            :no-data-text="$t('inputMessages.noDataText')"
                            persistent-hint
                            filled
                            required
                            @change="onPublicPlaceSelect"
                            clearable>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="serviceRequestForm.deliveryInAddress.streetNumber"
                              :label="$t('inputLabels.streetNumber')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              :no-data-text="$t('inputMessages.noDataText')"
                              counter="30"
                              max-length="30"
                              persistent-hint
                              filled
                              required
                              clearable>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="serviceRequestForm.deliveryInAddress.other"
                            :label="$t('infoToDeliver')"
                            :rules="inputRules.noSpecialChar"
                            :hint="$t('inputMessages.optionalField')"
                            persistent-hint
                            clearable
                            filled
                            clear-icon="mdi-close"
                            maxlength="50"
                            counter="50">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn text @click="deliveryInAddressModal = false">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn class="primary" @click="saveDeliveryInAddressData" :disabled="!deliveryInAddressForm">
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SZÁLLÍTÁSI CÍM MODAL END -->
    <!-- SZÁMLÁZÁSI CÍM MODAL -->
    <v-dialog v-model="billingAddressModal" max-width="800" persistent>
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-file-account-outline</v-icon>
          <span class="white--text">{{ $t('invoicingAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="billingAddressModal = false" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-form v-model="billingAddressForm">
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-radio-group v-model="serviceRequestForm.billingAddress.isCompany"
                               row mandatory class="mt-0" hide-details>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div :class="serviceRequestForm.billingAddress.isCompany === 0 ? 'bra' : 'bri'"
                           class="pa-3">
                        <v-radio :value="0">
                          <template slot="label">
                            <span class="text-uppercase br-label">{{ $t('inputLabels.privatePerson') }}</span>
                          </template>
                        </v-radio>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div :class="serviceRequestForm.billingAddress.isCompany === 1 ? 'bra' : 'bri'"
                           class="pa-3">
                        <v-radio :value="1">
                          <template slot="label">
                            <span class="text-uppercase br-label">{{ $t('inputLabels.company') }}</span>
                          </template>
                        </v-radio>
                      </div>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="serviceRequestForm.billingAddress.billingName"
                              :label="$t('invoicingName')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="serviceRequestForm.billingAddress.taxNumber"
                              :label="$t('vatNumber')"
                              :rules="serviceRequestForm.billingAddress.isCompany === 1 ? inputRules.vatRules : []"
                              :hint="serviceRequestForm.billingAddress.isCompany === 1 ? $t('inputMessages.requiredField') : $t('inputMessages.optionalField')"
                              v-mask="taxNumberMaskHU"
                              persistent-hint
                              filled>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="serviceRequestForm.billingAddress.postalCode"
                                :items="selectFieldOptions.postalCodesInvoice"
                                item-text="address.postalCode"
                                item-value="address.postalCode"
                                :search-input.sync="postalCodeSearchInvoice"
                                :label="$t('inputLabels.postalCode')"
                                :rules="inputRules.phoneRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                maxlength="4"
                                required
                                @change="onPostalCodeSelectInvoice"
                                @click:clear="clearAllAddressInputsInvoice"
                                clearable>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="serviceRequestForm.billingAddress.city"
                                :items="selectFieldOptions.citiesInvoice"
                                item-value="address.city"
                                item-text="address.city"
                                :search-input.sync="citySearchInvoice"
                                :label="$t('inputLabels.city')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                required
                                clearable
                                @click:clear="clearAllAddressInputsInvoice">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8">
                <v-combobox v-model="streetIdeiglenesInvoice"
                            :items="selectFieldOptions.streetsInvoice"
                            item-value="address.street"
                            item-text="address.street"
                            :search-input.sync="streetSearchInvoice"
                            :label="$t('inputLabels.publicPlaceName')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            :no-data-text="$t('inputMessages.noDataText')"
                            persistent-hint
                            filled
                            required
                            @change="onPublicPlaceSelectInvoice"
                            clearable>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="serviceRequestForm.billingAddress.streetNumber"
                              :label="$t('inputLabels.streetNumber')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              :no-data-text="$t('inputMessages.noDataText')"
                              counter="30"
                              max-length="30"
                              persistent-hint
                              filled
                              required
                              clearable>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="serviceRequestForm.billingAddress.other"
                            :label="$t('infoToDeliver')"
                            :rules="inputRules.noSpecialChar"
                            :hint="$t('inputMessages.optionalField')"
                            persistent-hint
                            clearable
                            filled
                            clear-icon="mdi-close"
                            maxlength="50"
                            counter="50">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn text @click="billingAddressModal = false">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn class="primary" @click="saveBillingAddressData" :disabled="!billingAddressForm">
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog"></loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </v-container>
</template>

<script>
import LoadingAndResponseDialog from 'src/components/dialogs/LoadingAndResponseDialog.vue';
import { HEREGETCITYBYPOSTALCODE, HEREGETPOSTALCODESBYCOUNTRY, HEREGETSTREETBYPOSTALCODE } from 'src/utils/hereAPI';
import { getURA } from 'src/utils/jwtHelper';
import dayjs from 'dayjs';

export default {
  name: 'MieleFormPage',
  components: {
    LoadingAndResponseDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    // if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE') {
    if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE' && await getURA().split(',').includes('MIELE')) {
      await this.setDates();
      await this.setServiceRequestFormCountry();
      await this.initForm();
      if (this.$route.params.ws === 'uj-megrendeles') {
        // this.serviceRequestForm.deliveryInAddress = this.$APPLE_STORE_ADDRESS;
        // this.saveDeliveryInAddressData();
        this.editable = true;
      } else {

      }
    } else {
      await this.$router.push({name: 'iranyitopult'});
    }
  },
  data() {
    return {
      serviceRequestForm: {
        formType: 22,
        processType: 1,
        prevCrmRequestId: null,
        prevGsmWorkSheetId: null,
        sourceSystem: null,
        externalId: null,
        serviceRequest: {
          partnerWorksheetNumber: null,
          dateOfDelivery: null,
          repairCostLimit: null,
          prevCrmRequestId: null,
          prevGsmWorkSheetId: null,
          availableServiceId: 98,
          brand: {},
          b2bRefNo: null,
          productGroup: {},
          productTypeFull: null,
          productType: {},
          uniqueIdentifierIn1: '',
          uniqueIdentifierIn2: '',
          uniqueIdentifierIn3: '',
          warrantyType: {},
          dateOfPurchase: null,
          errorDescription: '',
          networkLock: {},
          mobilProvider: {},
          insuranceNumber: '',
          rmaNumber: '',
          accessories: null,
          paymentMethod: null,
          deductible: null,
          isLoan: null,
          purchasePrice: null,
          otherComment: '',
        },
        customer: {
          id: null,
          customerType: 'MIELE',
          crmNamePrefixId: null,
          firstName: '',
          middleName: null,
          lastName: '',
          email: '',
          phoneNumber: '',
          password: null,
        },
        deliveryInAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        billingAddress: {
          id: null,
          isCompany: 0,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          label: '',
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        deliveryOutAddress: {},
        gdpr: false,
        termsAndCond: false,
        generalInfos: false,
        accountRegistration: null,
        isAutomaticQuotationAccepting: null,
        isVipService: null,
        isAcceptanceOfUsedParts: null,
        calculatedRepairPrice: null,
        calcDataId: null,
        isStoreDeliveryOut: 0,
        physicalDamages: 'Normál állapot, apró használati nyomok, karcok, sérülések',
        aestheticFaults: 'Normál állapot, apró használati nyomok, karcok, sérülések',
      },
      additionalDamages: {
        crmRequestId: null,
        additionalDamageData: {
          physicalDamage: {
            physicalDamageTypeId: null,
            physicalDamageTypeName: null,
            physicalDamageTypeDescription: 'Normál állapot, apró használati nyomok, karcok, sérülések',
          },
          aestheticFault: {
            aestheticFaultTypeId: null,
            aestheticFaultTypeName: null,
            aestheticFaultTypeDescription: 'Normál állapot, apró használati nyomok, karcok, sérülések',
          },
        },
      },
      files: [],
      requestForm: false,
      mask: '##-###-####',
      priceMask: '##########',
      dateTextMask: '####-##-##',
      taxNumberMaskHU: '########-#-##',
      maxDate: null,
      typeSearch: '',
      attachment: {
        invoice: null,
        warrantyCard: null,
      },
      editable: false,
      /**
       * SZÁLLÍTÁSI CÍMHEZ
       */
      deliveryInAddressString: '',
      deliveryInAddressForm: false,
      deliveryInAddressModal: false,
      countrySearch: '',
      countrySearchIsLoading: false,
      postalCodeSearch: '',
      postalCodeSearchIsLoading: false,
      citySearch: '',
      citySearchIsLoading: false,
      streetSearch: '',
      streetSearchIsLoading: false,
      streetIdeiglenes: '',
      deliveryInAddressBackup: null,
      /**
       * SZÁMLÁZÁSI CÍMHEZ
       */
      billingAddressString: '',
      billingAddressForm: false,
      billingAddressModal: false,
      postalCodeSearchInvoice: '',
      postalCodeSearchIsLoadingInvoice: false,
      citySearchInvoice: '',
      citySearchIsLoadingInvoice: false,
      streetSearchInvoice: '',
      streetSearchIsLoadingInvoice: false,
      streetIdeiglenesInvoice: '',
      billingAddressBackup: null,
      // számlázási cím megegyezik a szállítási címmel
      billingAddressIsEqualdeliveryInAddress: false,
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'loadingMessages.INPROGRESS_CONTACT_FORM',
        successText: 'SUCCESS_MESSAGES.APPLEWORKSHEET_SAVE',
        errorText: 'ERROR_MESSAGES.APPLEWORKSHEET_SAVE',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
      selectFieldOptions: {
        brands: [],
        productGroups: [],
        productTypes: [],
        warrantyType: [
          {
            id: 1,
            name: 'Garanciális javítás',
          },
          {
            id: 2,
            name: 'Fizetős javítás',
          },
        ],
        countries: [
          { label: 'Magyarország', countryCode: 'HUN' },
        ],
        countriesEN: [
          { label: 'Hungary', countryCode: 'HUN' },
        ],
        countriesDE: [
          { label: 'Ungarn', countryCode: 'HUN' },
        ],
        postalCodes: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        cities: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streets: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        customerShippingAddresses: [],
        customerBillingAddresses: [],
        // számlázási címhez selectek
        postalCodesInvoice: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        citiesInvoice: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streetsInvoice: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        physicalDamages: [],
        aestheticFaults: [],
      },
      inputRules: {
        acceptInformation: [v => !!v || this.$t('inputMessages.acceptInformation')],
        generalRules: [v => !!v || this.$t('inputMessages.requiredField')],
        emailRules: [
          v => /.+@.+\..+/.test(v) || this.$t('inputMessages.emailMatch'),
          v => !(/\s/.test(v)) || this.$t('inputMessages.emailNoSpace'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        phoneRules: [
          v => /^[0-9\-]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        gdprRules: [
          v => !!v || this.$t('inputMessages.requiredPrivacy'),
        ],
        termAndCondRules: [
          v => !!v || this.$t('inputMessages.requiredTermsAndCond'),
        ],
        maxFileSize: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
        ],
        maxFileSizeOptional: [
          v => this.checkMaxFileSizeOK(v) || this.$t('inputMessages.maxFileSizeRule'),
        ],
        requiredFile: [
          v => !v || v.size < 5000000 || this.$t('inputMessages.maxFileSizeRule'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        min8: [
          v => v.length >= 8 || this.$t('inputMessages.passwordRuleMin8'),
          v => !!v || this.$t('inputMessages.requiredField')
        ],
        imeiRule: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => /^[0-9]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => (v && v.length === 15) || this.$t('inputMessages.minLenght15'),
        ],
        onlyNumbers: [
          v => !!v || this.$t('inputMessages.optionalField'),
          v => /^[0-9]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
        ],
        snRule: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => /^[a-zA-Z0-9\-\/\\]*$/.test(v) || this.$t('inputMessages.snRules'),
          v => (v && v.length < 23) || this.$t('inputMessages.minLenght15'),
        ],
        selectRules: [
          v => !!v || this.$t('inputMessages.requiredField'),
          v => ( v && v.id > 0) || this.$t('inputMessages.requiredField'),
        ],
        purchaseDateRule: [
          v => !!v || this.$t('inputMessages.purchaseDateReq'),
          v => this.purchaseDateIsOK(v) || `${this.$t('inputMessages.purchaseDateMax')} ${this.maxDate}!`,
        ],
        vatRules: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => (v && v.length === 13) || this.$t('inputMessages.requiredField'),
        ],
        noSpecialCharAndReq: [
          (v) => !!v || this.$t('inputMessages.requiredField'),
          (v) => /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.requiredFieldNoSpecialChar'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
    };
  },
  methods: {
    async initForm() {
      const response = await this.$store.dispatch('showForm', 'MieleForm');
      if (response.status === 200) {
        // this.selectFieldOptions.warrantyType = JSON.parse(JSON.stringify(response.data.warrantyType));
        this.serviceRequestForm.serviceRequest.networkLock = JSON.parse(JSON.stringify(response.data.networkLock[0]));
        this.selectFieldOptions.brands = JSON.parse(JSON.stringify(response.data.brand));
        this.serviceRequestForm.serviceRequest.brand = JSON.parse(JSON.stringify(response.data.brand[0]));
        this.selectFieldOptions.productGroups = await this.callShowProductGroupOfBrand(this.serviceRequestForm.serviceRequest.brand);
        await this.callShowPaymentMethodOfCustomerType();
      }
    },
    checkMaxFileSizeOK(files) {
      if (files) {
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            if (files[i].size > 5000000) {
              return false;
            }
          }
        }
      }
      return true;
    },
    removeFileFromInput(file) {
      this.files.splice(this.files.indexOf(file), 1);
      this.files = [...this.files];
    },
    async callAppleWorksheetById(wsid) {
      const response = await this.$store.dispatch('GetAppleWorksheetById', { id: wsid });
    },
    /**
     *
     * Űrlap beküldése
     *
     */
    async sendServiceRequestForm() {
      this.openLARDialog();
      this.serviceRequestForm.deliveryOutAddress = JSON.parse(JSON.stringify(this.serviceRequestForm.deliveryInAddress));
      switch (this.serviceRequestForm.serviceRequest.warrantyType.id) {
        case 1:
          this.serviceRequestForm.serviceRequest.availableServiceId = 98;
          break;
        case 2:
          this.serviceRequestForm.serviceRequest.availableServiceId = 99;
          break;
      }
      this.serviceRequestForm.serviceRequest.mobilProvider = JSON.parse(JSON.stringify(this.serviceRequestForm.serviceRequest.networkLock));
      if (this.billingAddressIsEqualdeliveryInAddress) {
        this.serviceRequestForm.billingAddress.billingName = `${this.serviceRequestForm.customer.lastName} ${this.serviceRequestForm.customer.firstName}`;
      }
      // Purchase Price ellenőrzése
      if (this.serviceRequestForm.serviceRequest.purchasePrice === '') {
        this.serviceRequestForm.serviceRequest.purchasePrice = null;
      }
      if (this.serviceRequestForm.serviceRequest.purchasePrice) {
        if (this.serviceRequestForm.serviceRequest.purchasePrice.length < 1) {
          this.serviceRequestForm.serviceRequest.purchasePrice = null;
        }
      }
      if (this.serviceRequestForm.calculatedRepairPrice === '') {
        this.serviceRequestForm.calculatedRepairPrice = null;
      }
      if (this.serviceRequestForm.calculatedRepairPrice) {
        if (this.serviceRequestForm.calculatedRepairPrice.length < 1) {
          this.serviceRequestForm.calculatedRepairPrice = null;
        } else {
          this.serviceRequestForm.isAutomaticQuotationAccepting = 1;
        }
      }
      const response = await this.$store.dispatch('sendMobilfutarRequest',
        {form: this.serviceRequestForm, attachment: this.attachment });
      if (response.status === 200) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToSuccess();
      } else if (response.status === 201) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToSuccess();
      } else if (response.status === 422) {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.USER_EXISTS';
        this.changeLARDialogContentToError();
      } else if (response.status === 405) {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToError();
      } else {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.APPLEWORKSHEET_SAVE';
        this.changeLARDialogContentToError();
      }
    },
    async setDates() {
      const now = new Date();
      // const date = dayjs(now).subtract(3, 'day');
      this.maxDate = now.toISOString().substr(0, 10);
      if (this.$route.params.ws === 'uj-megrendeles') {
        this.serviceRequestForm.serviceRequest.dateOfDelivery = now.toISOString().substr(0, 10);
      }
    },
    async setServiceRequestFormCountry() {
      if (this.$i18n.locale === 'hu-HU') {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countries[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countries[0];
      } else if (this.$i18n.locale === 'de-DE') {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesDE[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesDE[0];
      } else {
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesEN[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesEN[0];
      }
    },
    /**
     * ÉRTÉKESÍTETT TARTOZÉKOK
     */
    addAccessory() {
      this.serviceRequestForm.serviceRequest.extraAccessories.push({ name: '', amount: null, });
      window.scrollTo(0, document.body.scrollHeight);
    },
    deleteAccessory(index) {
      // const ind = this.serviceRequestForm.serviceRequest.extraAccessories.findIndex(item);
      this.serviceRequestForm.serviceRequest.extraAccessories.splice(index, 1);
    },
    /**
     * SZÁLLÍTÁSI CÍM MEGADÁSÁHOZ FGV-EK
     */
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodes = response.data;
        this.postalCodeSearchIsLoading = false;
        this.selectFieldOptions.postalCodes.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      if (this.serviceRequestForm.deliveryInAddress.postalCode && this.serviceRequestForm.deliveryInAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.deliveryInAddress.postalCode.address.postalCode;
        this.serviceRequestForm.deliveryInAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.cities = response.data;
        this.citySearchIsLoading = false;
        this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streets = response.data;
        this.streetSearchIsLoading = false;
        this.selectFieldOptions.streets.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelect() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
        this.serviceRequestForm.deliveryInAddress.city = this.selectFieldOptions.cities[1].address.city;
        this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
      this.serviceRequestForm.deliveryInAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryInAddress.streetNumber = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
    },
    onPublicPlaceSelect() {
      // console.log('onPublicPlaceSelect');
      let street = '';
      let type = '';
      if (this.streetIdeiglenes && this.streetIdeiglenes.length > 0) {
        // console.log('1');
        street = this.streetIdeiglenes.substring(0, this.streetIdeiglenes.lastIndexOf(' '));
        type = this.streetIdeiglenes.substring(this.streetIdeiglenes.lastIndexOf(' '), this.streetIdeiglenes.length + 1).trim();
        if (this.streetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          // console.log('1b');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = this.streetIdeiglenes;
          type = this.selectFieldOptions.streets[1].address.street.substring(this.selectFieldOptions.streets[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streets[1].address.street.length + 1).trim();
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenes && this.streetIdeiglenes.address) {
        // console.log('2');
        street = this.streetIdeiglenes.address.street.substring(0, this.streetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenes.address.street.substring(this.streetIdeiglenes.address.street.lastIndexOf(' '), this.streetIdeiglenes.address.street.length + 1).trim();
        if (this.streetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          console.log('2b');
        }
      }
      //
      //
    },
    preventEnter() {
      //
    },
    clearAllAddressInputs() {
      this.selectFieldOptions.postalCodes = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.cities = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streets = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearch = '';
      this.postalCodeSearchIsLoading = false;
      this.citySearch = '';
      this.citySearchIsLoading = false;
      this.streetSearch = '';
      this.streetSearchIsLoading = false;
      this.streetIdeiglenes = '';
      this.serviceRequestForm.deliveryInAddress.postalCode = '';
      this.serviceRequestForm.deliveryInAddress.city = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryInAddress.streetNumber = '';
    },
    saveDeliveryInAddressData() {
      this.deliveryInAddressString = this.serviceRequestForm.deliveryInAddress.postalCode + ' '
        + this.serviceRequestForm.deliveryInAddress.city + ', '
        + this.serviceRequestForm.deliveryInAddress.publicPlaceName + ' '
        + this.serviceRequestForm.deliveryInAddress.publicPlaceType + ' '
        + this.serviceRequestForm.deliveryInAddress.streetNumber;
      this.deliveryInAddressModal = false;
    },
    /***
     * SZÁMLÁZÁSI CÍM MEGADÁSÁHOZ A FGV-EK
     */
    async getPostalCodesByCountryInvoice(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodesInvoice = response.data;
        this.postalCodeSearchIsLoadingInvoice = false;
        this.selectFieldOptions.postalCodesInvoice.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCodeInvoice() {
      let postalcode = 0;
      if (this.serviceRequestForm.billingAddress.postalCode && this.serviceRequestForm.billingAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.billingAddress.postalCode.address.postalCode;
        this.serviceRequestForm.billingAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.citiesInvoice = response.data;
        this.citySearchIsLoadingInvoice = false;
        this.selectFieldOptions.citiesInvoice.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCodeInvoice(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streetsInvoice = response.data;
        this.streetSearchIsLoadingInvoice = false;
        this.selectFieldOptions.streetsInvoice.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelectInvoice() {
      await this.getCityByPostalCodeInvoice();
      if (this.selectFieldOptions.citiesInvoice.length === 2) {
        this.serviceRequestForm.billingAddress.city = this.selectFieldOptions.citiesInvoice[1].address.city;
        this.citySearchInvoice = this.selectFieldOptions.citiesInvoice[1].address.city;
      }
      this.serviceRequestForm.billingAddress.publicPlaceName = '';
      this.serviceRequestForm.billingAddress.publicPlaceType = '';
      this.serviceRequestForm.billingAddress.streetNumber = '';
      this.streetIdeiglenesInvoice = '';
      this.streetSearchInvoice = null;
    },
    onPublicPlaceSelectInvoice() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.length > 0) {
        street = this.streetIdeiglenesInvoice.substring(0, this.streetIdeiglenesInvoice.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.substring(this.streetIdeiglenesInvoice.lastIndexOf(' '), this.streetIdeiglenesInvoice.length + 1).trim();
        if (this.streetIdeiglenesInvoice.lastIndexOf(' ') > 0) {
          // console.log('3a');
          this.serviceRequestForm.billingAddress.publicPlaceName = street;
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        } else {
          // console.log('3b');
          this.serviceRequestForm.billingAddress.publicPlaceName = this.streetIdeiglenesInvoice;
          type = this.selectFieldOptions.streetsInvoice[1].address.street.substring(this.selectFieldOptions.streetsInvoice[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streetsInvoice[1].address.street.length + 1).trim();
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.address) {
        street = this.streetIdeiglenesInvoice.address.street.substring(0, this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.address.street.substring(this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '), this.streetIdeiglenesInvoice.address.street.length + 1).trim();
        if (this.streetIdeiglenesInvoice.address.street.lastIndexOf(' ') > 0) {
          // console.log('4a');
          this.serviceRequestForm.billingAddress.publicPlaceName = street;
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        } else {
          console.log('4b');
        }
      }
    },
    clearAllAddressInputsInvoice() {
      this.selectFieldOptions.postalCodesInvoice = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.citiesInvoice = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streetsInvoice = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearchInvoice = '';
      this.postalCodeSearchIsLoadingInvoice = false;
      this.citySearchInvoice = '';
      this.citySearchIsLoadingInvoice = false;
      this.streetSearchInvoice = '';
      this.streetSearchIsLoadingInvoice = false;
      this.streetIdeiglenesInvoice = '';
      this.serviceRequestForm.billingAddress.postalCode = '';
      this.serviceRequestForm.billingAddress.city = '';
      this.serviceRequestForm.billingAddress.publicPlaceType = '';
      this.serviceRequestForm.billingAddress.publicPlaceName = '';
      this.serviceRequestForm.billingAddress.streetNumber = '';
    },
    saveBillingAddressData() {
      this.billingAddressString = this.serviceRequestForm.billingAddress.postalCode + ' '
        + this.serviceRequestForm.billingAddress.city + ', '
        + this.serviceRequestForm.billingAddress.publicPlaceName + ' '
        + this.serviceRequestForm.billingAddress.publicPlaceType + ' '
        + this.serviceRequestForm.billingAddress.streetNumber;
      this.billingAddressModal = false;
    },
    openBillingAddressModal() {
      // a meglévő szállítási címet kimentjük egy ideiglenes változóba,
      // hogy ha a user a Mégsem gombra kattint, akkor megmaradjon az előzőleg beírt cím
      this.billingAddressBackup = this.serviceRequestForm.billingAddress;
      this.billingAddressModal = true;
    },
    closeBillingAddressModalWithoutSave() {
      // Mégsem gombra kattintva visszaszerezzük az előzőleg beírt címet
      this.serviceRequestForm.billingAddress = this.billingAddressBackup;
      this.billingAddressModal = false;
      this.billingAddressBackup = null;
    },
    purchaseDateIsOK(date) {
      const inputDate = dayjs(date);
      const maxD = dayjs(this.maxDate);
      const max = dayjs(maxD).add(1, 'day');
      if (date) {
        if (date.length === 10) {
          if (new Date(date).getDate()) {
            return inputDate.isBefore(dayjs(max));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    /**
     *
     * Loading and response dialog
     *
     */
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    closeLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError(errorMessage) {
      if (errorMessage) {
        this.loadingAndResponseDialog.errorText = `ERROR_MESSAGES.${errorMessage}`;
      }
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.closeLARDialog();
      this.$router.push({ name: 'apple-munkalap' });
    },
    onErrorClickInLARDialog() {
      this.closeLARDialog();
    },
    /*
     * TERMÉK CSOPORT LEKÉRDEZÉSE GYÁRTÓ ALAPJÁN
     */
    async callShowProductGroupOfBrand(brand) {
      const resp = await this.$store.dispatch('showProductGroupOfBrand', brand.id);
      // console.log('resp: ', resp);
      // const conv = await this.ProductGroupConverter(resp.data);
      // this.selectFieldOptions.productGroups = resp.data.sort((a, b) => ((a.name > b.name) ? 1 : -1));
      return resp.data.sort((a, b) => ((a.name > b.name) ? 1 : -1));
      // return conv.sort((a, b) => ((a.displayName > b.displayName) ? 1 : -1));
    },
    /*
     * TERMÉK TÍPUS LEKÉRDEZÉSE GYÁRTÓ ÉS TERMÉK TÍPUS ALAPJÁN
     */
    async callShowProductTypeOfBrand() {
      // console.log('callShowProductTypeOfBrand start...');
      const response = await this.$store.dispatch('showProductTypeOfBrand',
        { brandId: this.serviceRequestForm.serviceRequest.brand.id, productGroupId: this.serviceRequestForm.serviceRequest.productGroup.id });
      // console.log('callShowProductTypeOfBrand: ', response);
      if (response.status === 200) {
        response.data.sort((a, b) => ((a.productTypeShoppingName > b.productTypeShoppingName) ? 1 : -1));
        // ha APPLE brand.id = 2, akkor nem kell
        const benne = this.$NoDisplayNameBrands.includes(this.serviceRequestForm.serviceRequest.brand.id);
        if (benne) {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].displayName = `${response.data[i].productTypeShoppingName}`;
          }
        } else {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].displayName = `${response.data[i].productTypeShoppingName} - ${response.data[i].gsmTipusNev}`;
          }
        }
        this.selectFieldOptions.productTypes = response.data;
      }
    },
    /*
     * USER-TYPE-HOZ TARTOZÓ PAYMENT-METHODOK LEKÉRDEZÉSE
     */
    async callShowPaymentMethodOfCustomerType() {
      const response = await this.$store.dispatch('showPaymentMethodOfCustomerType', this.serviceRequestForm.customer.customerType);
      if (response.status === 200) {
        this.serviceRequestForm.serviceRequest.paymentMethod = response.data[0];
      }
    },
    async getAppleShopDocument(id, docType) {
      const response = await this.$store.dispatch('DownloadAppleShopDocuments', { id: id, docType: docType });
      return response;
    },
  },
  watch: {
    postalCodeSearch(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountry(value);
    },
    streetSearch(value) {
      this.streetSearchIsLoading = true;
      this.getStreetByPostalCode(value);
    },
    postalCodeSearchInvoice(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountryInvoice(value);
    },
    streetSearchInvoice(value) {
      this.streetSearchIsLoading = true;
      this.getStreetByPostalCodeInvoice(value);
    },
    billingAddressIsEqualdeliveryInAddress(value) {
      if (value) {
        this.serviceRequestForm.billingAddress.postalCode = this.serviceRequestForm.deliveryInAddress.postalCode;
        this.serviceRequestForm.billingAddress.city = this.serviceRequestForm.deliveryInAddress.city;
        this.serviceRequestForm.billingAddress.publicPlaceName = this.serviceRequestForm.deliveryInAddress.publicPlaceName;
        this.serviceRequestForm.billingAddress.publicPlaceType = this.serviceRequestForm.deliveryInAddress.publicPlaceType;
        this.serviceRequestForm.billingAddress.streetNumber = this.serviceRequestForm.deliveryInAddress.streetNumber;
        this.serviceRequestForm.billingAddress.other = this.serviceRequestForm.deliveryInAddress.other;
        this.saveBillingAddressData();
      } else {
        this.clearAllAddressInputsInvoice();
        this.serviceRequestForm.billingAddress.billingName = '';
        this.serviceRequestForm.billingAddress.taxNumber = '';
        this.serviceRequestForm.billingAddress.other = '';
        this.billingAddressString = '';
      }
    },
    'serviceRequestForm.serviceRequest.productGroup': function (newVal) {
      // console.log('newVal: ', newVal);
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1 = null;
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2 = null;
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3 = null;
      this.serviceRequestForm.serviceRequest.productTypeFull = null;
      this.selectFieldOptions.productTypes = [];
      // this.selectedProductGroup = this.serviceRequestForm.serviceRequest.productGroup.find(item => item.productGroup.id === newVal.id);
      if (newVal.id) {
        this.callShowProductTypeOfBrand();
      }
    },
    'serviceRequestForm.serviceRequest.productTypeFull': function (newVal) {
      this.serviceRequestForm.serviceRequest.productType = {};
      this.typeSearch = '';
      if (newVal) {
        this.serviceRequestForm.serviceRequest.productType.id = newVal.productTypeId;
        this.serviceRequestForm.serviceRequest.productType.name = newVal.productTypeShoppingName;
      }
    },
    'serviceRequestForm.isStoreDeliveryOut': function (value) {
      if (value === 1) {
        this.serviceRequestForm.deliveryInAddress = JSON.parse(JSON.stringify(this.$APPLE_STORE_ADDRESS));
        this.saveDeliveryInAddressData();
      } else {
        this.serviceRequestForm.deliveryInAddress = JSON.parse(JSON.stringify(this.$ADDRESS_TEMPLATE));
        this.deliveryInAddressString = '';
      }
    },
  }
};
</script>

<style scoped>

</style>
